/* eslint-disable */
import girl1 from "../assets/svg/testimonials/girl1.svg";
import girl2 from "../assets/svg/testimonials/girl2.svg";
import girl3 from "../assets/svg/testimonials/girl3.svg";
import boy1 from "../assets/svg/testimonials/boy1.svg";
import boy2 from "../assets/svg/testimonials/boy2.svg";
import boy3 from "../assets/svg/testimonials/boy3.svg";
import img from "../assets/svg/testimonials/photo_2023-12-23_18-42-28-removebg-preview.png"
import img1 from "../assets/svg/testimonials/tube1.png"
import img2 from "../assets/svg/testimonials/derebest.png"
import jhon from "../assets/png/jhon3.jpg"
import elsa from "../assets/png/elza.jpg"


export const testimonialsData = [
  {
    id: 1,
    name: "Dr Getnet Bogale",
    title: "Data Science Engineer, Atrons CEO",
    text: " we really are lucky to have him with us!",
    image: img,
  },
  {
    id: 2,
    name: "Yohannes Bekele",
    title: "Innovator Entrepreneur Ceo of Haddis Art",
    text: "passinated, hardworking technologist ",
    image: jhon,
  },
  {
    id: 3,
    name: "Elsabet",
    title: "Software Developer",
    text: "As a highly skilled and reliable developer",
    image: elsa,
  },
  {
    id: 4,
    name: "Dereje Chinkl",
    title: "Machine Learning Engineer",
    text: "Quick learner and adaptable, adept at staying abreast of current industry practices, a humble gentleman with a commitment to continuous improvement and collaboration.",
    image: img2,
  },

  
  {
    id: 5,
    name: "Tewobsta Tewoflos",
    title: "Software Engineer",
    text: "passinated, hardworking technologist, dedicated to driving innovation",
    image: img1,
  },

];
