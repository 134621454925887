// import resume from "../assets/pdf/NatiWosen2-Resume.pdf";
import image from '../assets/png/Developer.jpg'

export const headerData = {
  name: "Natnael Wosen",
  title: "FullStack Developer",
  desciption:
    "a Software Developer Specialized on MERN Stack  Development, based in Ethiopia.",
  image: {image},
  resumePdf: "https://naticv.netlify.app",
};
